/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import React from 'react';
import createPlotlyComponent from 'react-plotly.js/factory';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Plotly from 'plotly.js';
import { sqrt } from 'mathjs';
import * as actions from '../actions';
import { MessageBar, MessageBarType } from '@fluentui/react';
import * as TextMapping from '../utils/textMapping';
import LTHADialog from './dialogs/LTHADialog';
import CLTEDialog from './dialogs/CLTEDialog';
import { convertSvgToPngAndCopy } from '../utils/plotutils'

const PlotlyPlot = createPlotlyComponent(Plotly);

const mapStateToProps = (state) => {
  return {
    roots: state.roots,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(actions, dispatch);
};

class Plot extends React.Component {
  constructor(props) {
    super(props);
    this.previewBtn = null;
    this.state = {
      plotly: { plot: null, layout: {} },
      width: 0,
      height: 0,
      legendWidth: 0,
      orientation: 'portrait',
      downloaded: false,
      hiddenPlotInitialized: false,
      downloadPDFClicked: false,
      copyClicked: false,
    };
    this.spanElement = React.createRef();
    this.commentElement = React.createRef();
    this.hiddenPlotElement = React.createRef();
    this.plotResize = props.plotResize;
    this.fullScreen = props.fullScreen;
    this.onRelayout = props.onRelayout;
    this.onSearchSelected = props.onSearchSelected;
    this.onDoubleClick = props.onDoubleClick;
    this.onInitialized = props.onInitialized;
    this.onUpdate = props.onUpdate;
    this.id = props.id;
    this.comment = props.comment;
    this.plotlyRef = React.createRef();
    this.resizeObserver = null;
    this.magicNumber = props.magicNumber ? props.magicNumber : 197;
    // this.modeBarButtonsToAdd = [];
    // let newModeBarButtons = [];
    this.downloadPDF = props.downloadPDF;
    this.downloadXLS = props.downloadXLS;
    this.state = { modeBarButtonsToAdd: this.configModeBarButtons(props) };
    this.showDownloadMessage = this.showDownloadMessage.bind(this);
    this.onHiddenPlotInitialized = this.onHiddenPlotInitialized.bind(this);
    this.onDismissLTHA = this.onDismissLTHA.bind(this);
    this.onDismissCLTE = this.onDismissCLTE.bind(this);
    this.handlePercentageChange = this.handlePercentageChange.bind(this);
    this.handleShowControlPoints = this.handleShowControlPoints.bind(this);
  }

  configModeBarButtons(props) {
    let newModeBarButtons = [];
    if (props.roots && props.roots.config && props.roots.config.plotly && props.roots.config.plotly.modeBarButtonsToAdd) {
      for (let modeBarButton of props.roots.config.plotly.modeBarButtonsToAdd) {
        let newButton = {};
        if (!this.validateModebars(modeBarButton, props.name, props.plotData)) continue;
        if (modeBarButton.action === 'ltha') {
          newButton.icon = modeBarButton.icon;
          newButton.name = modeBarButton.name;

          let self = this;
          newButton.click = function () {
            self.showLTHADialog(self.props.plotData);
          };
        } else if (modeBarButton.action === 'clte') {
          newButton.icon = modeBarButton.icon;
          newButton.name = modeBarButton.name;

          let self = this;
          newButton.click = function () {
            self.showCLTEDialog(self.props.plotData);
          };
        } else if (modeBarButton.action === 'xlsexport') {
          newButton.icon = modeBarButton.icon;
          newButton.name = modeBarButton.name;
          let self = this;
          newButton.click = function () {
            if (self.props.id) {
              self.showDownloadMessage();
              self.downloadXLS(self.props.id);
            }
          };
        } else if (modeBarButton.action === 'pdf') {
          newButton.icon = modeBarButton.icon;
          newButton.name = modeBarButton.name;
          let self = this;
          newButton.click = function () {
            self.showDownloadMessage();

            self.setState(() => {
              return { downloadPDFClicked: true };
            });
          };
        } else if (modeBarButton.action === 'searchscatter') {
          newButton.icon = modeBarButton.icon;
          newButton.name = modeBarButton.name;
          let self = this;
          newButton.click = function () {
            self.onSearchSelected();
          };
        } else if (modeBarButton.action === 'doubleclick') {
          newButton.icon = modeBarButton.icon;
          newButton.name = modeBarButton.name;
          let self = this;
          newButton.click = function () {
            self.onDoubleClick();
          };
        }else if (modeBarButton.action === 'copy') {
          newButton.icon = modeBarButton.icon;
          newButton.name = modeBarButton.name;
          let self = this;
          newButton.click = function () {
            self.setState(() => {
              return { copyClicked: true };
            });
          };
        } else {
          newButton = null;
        }

        newButton && newModeBarButtons.push(newButton);
      }
    }
    return newModeBarButtons;
  }

  //Checking for custom modebars to be added for plotly chart
  validateModebars(modebar, chartname, plotData) {
    let permissions = this.props.roots.perms;
    //If context for specific diagram type => chartname.vkey
    let matchedVkeyAndChart = false;
    if (modebar.context && modebar.context.length > 0) {
      for (let context of modebar.context) {
        let parts = context.split('.');
        if (parts.length === 2) {
          matchedVkeyAndChart = parts[0] === chartname && parts[1] === plotData.vkey.toString();
        } else {
          matchedVkeyAndChart = parts[0] === chartname;
        }
        if (matchedVkeyAndChart) {
          break;
        }
      }
    }
    // check for user permission with modebar action permission
    let hasPermissions = !modebar.permission || modebar.permission.length === 0 ? true : false; // default
    if (!hasPermissions && modebar.permission) {
      hasPermissions = permissions.some((perm) => modebar.permission.includes(perm)) ? true : false;
    }
    //If context is * modebar is available for all chart types
    matchedVkeyAndChart = matchedVkeyAndChart || (modebar.context && modebar.context === '*');
    return matchedVkeyAndChart && hasPermissions;
  }

  async onHiddenPlotInitialized() {
    const hiddenPlot = this.hiddenPlotElement.el;
    // gd -> div
    // exporting pdf from the svg generated on the fly
    let width = 500;
    let height = 588;
    if (this.props.plotData.orientation === 'landscape' || this.props.plotData.orientation === 'unconstrained') {
      height = 376;
    }
    const url = await Plotly.toImage(hiddenPlot, { height: height, width: width, format: 'svg' });
    if (this.state.copyClicked) {
      //copy diagram to clipboard
      const copyStatus = await convertSvgToPngAndCopy(url);
      if (copyStatus.success) this.showCopyMessage();
      this.setState({ copyClicked: false });
    } else if (this.state.downloadPDFClicked) {
      //download pdf
      const response = await fetch(url);
      const data = await response.text();
      let materialTitle;
      let materialname;
      if (this.props.roots.materialDatasheet.data && this.props.plotData.layout.title.text) {
        materialTitle = this.props.roots.materialDatasheet.data.filter((d) => d.type === 'title')[0];
        materialname = materialTitle.data.content || '';
      }
      const pdfTitle = materialname
        ? materialname + '_' + this.props.plotData.layout.title.text + '.pdf'
        : this.props.plotData.layout.title.text
          ? this.props.plotData.layout.title.text + '.pdf'
          : this.props.name + '.pdf';

      let materialId = (materialTitle && materialTitle.data.materialId) || '0';
      this.downloadPDF(materialId, pdfTitle, this.props.roots.appContent, this.props.name, data, this.comment);
      this.setState({ downloadPDFClicked: false });
    }
  }

  downloadPlotAndInitState(plotData) {
    if (plotData) {
      this.setState({
        plotly: { plot: plotData.data, layout: { ...plotData.layout, autosize: true } },
        orientation: plotData.orientation,
        downloaded: true,
      });
    }
  }

  onDismissLTHA() {
    this.setState({ lthaDialogHidden: true });
  }

  onDismissCLTE() {
    this.setState({ clteDialogHidden: true });
  }

  handlePercentageChange(value) {
    this.showLTHADialog(this.props.plotData, value);
  }

  handleShowControlPoints(value) {
    this.showCLTEDialog(this.props.plotData, value);
  }

  componentDidMount() {
    this.resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.contentBoxSize && entry.contentBoxSize.length > 0) {
          let commentHeight = this.commentElement?.current?.clientHeight;

          this.setState(() => {
            return {
              width: entry.contentBoxSize[0].inlineSize,
              height: entry.contentBoxSize[0].blockSize,
              commentHeight: Math.min(entry.contentBoxSize[0].blockSize < 400 ? 0 : entry.contentBoxSize[0].blockSize / 5, commentHeight),
            };
          });
        }
      }
    });

    this.resizeObserver.observe(this.spanElement.current.parentElement);

    /*
    this.commentResizeObserver = new ResizeObserver(entries => {
      console.log('### resize', entries);
      for (let entry of entries) {
        if (entry.contentBoxSize && entry.contentBoxSize.length > 0) {
          console.log('### resize: ', entry.contentBoxSize[0].blockSize);
          this.setState(state => {
            return {
              commentHeight: Math.min(this.state.height < 400 ? 0 : this.state.height / 5, entry.contentBoxSize[0].blockSize),
            };
          });
        }
      }
    });

    this.commentResizeObserver.observe(this.commentElement?.current);
    */
  }

  UNSAFE_componentWillMount() {
    let plotData = this.props.plotData;
    if (plotData) {
      this.downloadPlotAndInitState(plotData);
    }
  }

  componentWillUnmount() {
    this.resizeObserver.unobserve(this.spanElement.current);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (this.props.plotData !== newProps.plotData) {
      this.downloadPlotAndInitState(newProps.plotData);
    }

    if (newProps.fullScreen && !this.fullScreen) {
      this.plotlyRef.resizeHandler();
    }

    this.onRelayout = newProps.onRelayout;
    this.onDoubleClick = newProps.onDoubleClick;

    this.fullScreen = newProps.fullScreen;
    this.fullScreen = newProps.fullScreen;

    this.comment = newProps.comment;

    // let newModeBarButtons = [];
    this.setState({
      modeBarButtonsToAdd: this.configModeBarButtons(newProps),
    });

    // this.modeBarButtonsToAdd = this.state.modeBarButtonsToAdd;
  }

  showDownloadMessage() {
    this.setState({ downloadMessage: TextMapping.getUIText(TextMapping.UI_TEXT_DOWMLOAD_MESSAGE, this.props.roots.texts) });
    setTimeout(() => {
      this.setState({ downloadMessage: false });
    }, 3500);
  }

  showCopyMessage() {
    this.setState({ downloadMessage: TextMapping.getUIText(TextMapping.UI_TEXT_COPY_SUCCESS_MESSAGE, this.props.roots.texts) });
    setTimeout(() => {
      this.setState({ downloadMessage: false });
    }, 3500);
  }

  showCLTEDialog(plotData) {
    let x = [];
    let y = [];

    let initialY = null;

    for (let data of plotData.data) {
      if (!initialY) {
        initialY = data.y[0];
      }
      x.push(data.x);
      y.push(data.y);
    }

    var cltedata3 = {
      name: plotData.layout.title.text,
      name2: '',
      nameRef: null,
      moist: 0,
      orientation: 'L',
      axes: {
        x: {
          name: plotData.layout.xaxis.axislabel,
          unit: plotData.layout.xaxis.axisunit,
          log: false,
        },
        y: {
          name: plotData.layout.yaxis.axislabel,
          unit: plotData.layout.yaxis.axisunit,
          log: false,
        },
        z: {
          name: '',
          unit: '',
        },
        x2: {
          name: 'Temperature',
          unit: '\u00b0C',
          log: false,
        },
        y2: {
          name: plotData.layout.yaxis2.title.text,
          unit: '',
          log: false,
        },
        z2: {
          name: '',
          unit: '',
        },
      },
      x: x,
      y: y,
      z: [
        { value: null, unit: '', name: '', comment: null, axis: 'y', validcount: 0 },
        { value: null, unit: '', name: '', comment: null, axis: 'y2', validcount: 0 },
      ],
      keys: [''],
      comment: '',
      standardcomment: null,
      watermark: '',
      styles: {
        watermark: {
          fill: '#969696',
          'font-size': '0.8em',
        },
        comment: {
          'font-size': '0.8em',
        },
        legend: {
          border: {
            stroke: 'black',
            'stroke-width': '1px',
            fill: 'none',
          },
        },
        axes: {
          x: { 'stroke-width': 2 },
          y: { 'stroke-width': 2 },
        },
      },
      config: {
        orientation: 'L',
        width: 800,
        height: 800,
        gridsize: 0,
        thumbnail: false,
        zoom: false,
        tooltip: false,
        restricted: false,
        target: '',
        stroke: ['width:3', 'width:3'],
        toolbar: {
          icons: { togglecurvepoints: true },
          pos: 'right',
          iconSize: 18,
        },
        switchColors: [],
        curvepoints: {
          show: true,
          color: 'curve',
          fillcolor: 'white',
          fill: 1,
          radius: 3,
        },
        watermark: {
          position: 'fixed',
          x: 10,
          y: 20,
        },
        titlebar: {
          repositiontitle: false,
          offsetlogo: 20,
          logoRight: true,
          innerColor: 'rgb(255,255,255)',
          outsideColor: 'rgb(255,255,255)',
          logoWidth: 350,
          logoHeight: 150,
          padding: {
            top: 10,
          },
          yoffsettitle: 15,
        },
        isround: true,
        colors: {
          linecolors: [
            { R: 228, G: 20, B: 1 },
            { R: 0, G: 137, B: 2 },
            { R: 0, G: 80, B: 238 },
            { R: 215, G: 192, B: 2 },
            { R: 161, G: 0, B: 37 },
            { R: 96, G: 169, B: 23 },
            { R: 27, G: 159, B: 224 },
            { R: 239, G: 161, B: 10 },
            { R: 216, G: 0, B: 115 },
            { R: 106, G: 1, B: 253 },
            { R: 109, G: 134, B: 100 },
            { R: 249, G: 105, B: 1 },
            { R: 121, G: 59, B: 63 },
            { R: 163, G: 195, B: 1 },
            { R: 169, G: 0, B: 254 },
            { R: 1, G: 170, B: 169 },
            { R: 101, G: 118, B: 135 },
            { R: 243, G: 115, B: 207 },
            { R: 130, G: 90, B: 44 },
            { R: 118, G: 95, B: 136 },
          ],
          bordercolor: { R: 0, G: 0, B: 0 },
          gridcolor: { R: 190, G: 190, B: 190 },
          labelcolor: { R: 0, G: 0, B: 0 },
          axisxcolor: { R: 0, G: 0, B: 0 },
          axisycolor: { R: 0, G: 0, B: 0 },
        },
        axes: {
          x: { subticks: true },
          y: { subticks: true },
        },
        aspectRatio: false,
        clte: {
          start: plotData.layout.xaxis.axisunit.includes('C') ? 23 : 75,
          end: plotData.layout.xaxis.axisunit.includes('C') ? 55 : 130,
          special: plotData.layout.xaxis.axisunit.includes('C') ? 23 : 75,
        },
      },
      add: [[''], ['']],
      istraight: 23,
    };

    this.setState({ clteDialogHidden: false, clteDialogPlotData: cltedata3 });
  }

  showLTHADialog(plotData, percentageRepresentation = false) {
    /* convert plotData */

    let x = [];
    let y = [];
    let z = [];

    let unit = null;

    const initialY = plotData?.data[0]?.y[0];

    for (let data of plotData.data) {
      x.push(data.x);
      if (!percentageRepresentation) {
        y.push(data.y);
      } else {
        y.push(
          data.y.map((value) => {
            return (value / initialY) * 100;
          })
        );
      }
      let nameParts = data.name.split(' ');
      if (!unit) {
        unit = nameParts[1];
      }

      z.push({ value: nameParts[0], unit: nameParts[1], name: '', comment: '', validcount: 0 });
    }

    var ltha1data = {
      name: plotData.layout.title.text,
      name2: '',
      orientation: 'L',
      axes: {
        x: {
          name: plotData.layout.xaxis.axislabel,
          unit: plotData.layout.xaxis.axisunit,
          log: plotData.layout.xaxis.type !== 'linear',
        },
        y: {
          name: plotData.layout.yaxis.axislabel,
          unit: plotData.layout.yaxis.axisunit,
          log: plotData.layout.yaxis.type !== 'linear',
        },
        z: {
          name: '',
          unit: unit,
        },
      },
      x: x,
      y: y,
      z: z,
      keys: [],
      comment: '',
      standardcomment: null,
      watermark: '',
      styles: {
        font: '',
        'font-size': '1em',
        watermark: {
          fill: '#969696',
          'font-size': '0.8em',
        },
        comment: {
          'font-size': '0.8em',
        },
      },
      config: {
        //"width": 400,
        //"height": 400,
        axes: {
          maxTicks: 8,
        },
        orientation: 'L',
        //"gridsize": 450,
        switchColors: [],
        thumbnail: false,
        percswitch: false,
        zoom: true,
        tooltip: true,
        restricted: false,
        target: '',
        stroke: ['width:2', 'width:2', 'width:2', 'width:2', 'width:2'],
      },
      add: ['', '', '', '', ''],
      istraight: 50,
    };

    this.setState({ lthaDialogHidden: false, lthaDialogPlotData: ltha1data });
  }

  hideLTHADialog() {
    this.setState({ lthaDialogHidden: true, lthaDialogPlotData: null });
  }

  render() {
    // Preview button only if this user has permissions
    this.previewBtn = null;
    if (this.props.canUpdate) {
      this.previewBtn.modelid = this.props.modelid;
      this.previewBtn.uid = this.props.uid;
    }

    /* let topMargin = 50;
    let bottomMargin = 50;
    let leftMargin = 50;
    let rightMargin = 50;

    let autosize = true;
    */

    let heightDifference = 0;
    let widthDifference = 0;
    let calculatedHeight = 0;

    let topMargin = 100;
    let bottomMargin = 80;
    let leftMargin = 80;
    let rightMargin = 80;

    let minimumGridWidth = 100;
    let minimumGridHeight = 100;

    let minimumWidth = minimumGridWidth + leftMargin + rightMargin;
    let minimumHeight = minimumGridHeight + topMargin + bottomMargin + this.state.commentHeight;

    let disableMarginContent = false;

    if (this.props.plotData && this.props.plotData.legendLength > 5) {
      // Adjust right margin based on number of characters in legend
      rightMargin += (this.props.plotData.legendLength - 5) * 6;
    }

    if (this.state.width < minimumWidth || this.state.height < minimumHeight) {
      disableMarginContent = true;
      leftMargin = 20;
      topMargin = 20;
      rightMargin = 20;
      bottomMargin = 20;
    }

    if (this.state.width !== 0 && this.state.height !== 0 && this.props.plotData && this.props.plotData.orientation !== 'unconstrained') {
      const targetAspect = 1 / sqrt(2);
      const targetHeight =
        this.state.orientation === 'portrait'
          ? (this.state.width - leftMargin - rightMargin) / targetAspect + topMargin + bottomMargin
          : (this.state.width - leftMargin - rightMargin) * targetAspect + topMargin + bottomMargin;

      heightDifference = this.state.height - targetHeight;

      calculatedHeight = this.state.height - heightDifference;

      if (calculatedHeight + this.state.commentHeight > this.spanElement.current?.parentElement?.clientHeight) {
        // try setting width instead
        calculatedHeight = this.state.height - this.state.commentHeight;

        const targetWidth =
          this.state.orientation === 'portrait'
            ? (calculatedHeight - topMargin - bottomMargin) * targetAspect + (leftMargin + rightMargin)
            : (calculatedHeight - topMargin - bottomMargin) / targetAspect + (leftMargin + rightMargin);

        widthDifference = this.state.width - targetWidth;
      }
    }

    let plotComponent = '';
    if (this.props.plotData && this.props.plotData.data && this.props.plotData.data.length === 0) {
      // plotComponent = (
      //   <div
      //     style={{
      //       width: '100%',
      //       height: '100%',
      //       fontFamily: 'Noto Sans',
      //       display: 'flex',
      //       justifyContent: 'center',
      //       alignItems: 'center',
      //     }}
      //   >
      //     Loading plot...
      //   </div>
      // );
    } else if (this.props.plotData && this.props.plotData.data && this.props.plotData.data.length > 0) {
      let updatedLayout = {
        ...this.state.plotly.layout,
        showlegend: !disableMarginContent ? this.state.plotly.layout.showlegend : false,
        autosize: false,
        height: calculatedHeight ? calculatedHeight : this.state.height,
        width: this.state.width - widthDifference,
        legend: {
          font: {
            size: 12,
          },
        },
        title: !disableMarginContent ? this.state.plotly.layout.title : '',
        yaxis: {
          automargin: true,
          ...this.state.plotly.layout.yaxis,
          title: disableMarginContent ? '' : this.state.plotly.layout.yaxis.title,
          showticklabels: !disableMarginContent,
        },
        xaxis: {
          ...this.state.plotly.layout.xaxis,
          title: disableMarginContent ? '' : this.state.plotly.layout.xaxis.title,
          showticklabels: !disableMarginContent ? true : false,
        },
      };

      if (disableMarginContent) {
        updatedLayout.margin = { t: 20, b: 20, l: 20, r: 20 };
      }

      plotComponent = (
        <PlotlyPlot
          data={this.state.plotly.plot}
          layout={updatedLayout}
          onRelayout={this.onRelayout}
          onDoubleClick={this.onDoubleClick}
          onSearchSelected={this.onSearchSelected}
          onInitialized={this.onInitialized}
          onUpdate={this.onUpdate}
          ref={(plotlyRef) => {
            this.plotlyRef = plotlyRef;
          }}
          useResizeHandler={true}
          style={{
            height: calculatedHeight,
            width: this.state.width - widthDifference,
          }}
          config={{
            modeBarButtonsToRemove:
              this.props.roots &&
              this.props.roots.config &&
              this.props.roots.config.plotly &&
              this.props.roots.config.plotly.modeBarButtonsToRemove
                ? this.props.roots.config.plotly.modeBarButtonsToRemove
                : ['toImage', 'sendDataToCloud'],
            modeBarButtonsToAdd: this.state.modeBarButtonsToAdd,
            displaylogo: false,
            scrollZoom: true,
            showTips: true,
            doubleClick: false,
          }}
        />
      );
    }
    //To handle printing plots in one standerd size.
    let hiddenComponent = '';
    if (this.props.plotData && this.props.plotData.data && this.props.plotData.data.length > 0) {
      let updatedLayout = {
        ...this.state.plotly.layout,
        autosize: false,
      };
      updatedLayout.margin = {
        t: 100,
        b: 100,
        l: 100,
        r: 100,
      };
      if (this.props.name === 'overlay') {
        updatedLayout.margin = {
          t: 120,
          b: 120,
        };
      }

      if (this.state.downloadPDFClicked || this.state.copyClicked) {
        hiddenComponent = (
          <PlotlyPlot
            data={this.state.plotly.plot}
            layout={updatedLayout}
            onRelayout={null}
            onDoubleClick={null}
            onSearchSelected={null}
            ref={(hiddenPlotElement) => {
              this.hiddenPlotElement = hiddenPlotElement;
            }}
            useResizeHandler={false}
            onInitialized={this.onHiddenPlotInitialized}
            style={{ width: '466px', height: '620px' }}
            config={{
              modeBarButtonsToRemove:
                this.props.roots &&
                this.props.roots.config &&
                this.props.roots.config.plotly &&
                this.props.roots.config.plotly.modeBarButtonsToRemove
                  ? this.props.roots.config.plotly.modeBarButtonsToRemove
                  : ['toImage', 'sendDataToCloud'],
              modeBarButtonsToAdd: this.state.modeBarButtonsToAdd,
              displaylogo: false,
              scrollZoom: false,
              showTips: false,
              doubleClick: false,
            }}
          />
        );
      }
    }

    return (
      <React.Fragment>
        <LTHADialog
          hidden={this.state.lthaDialogHidden}
          plotData={this.state.lthaDialogPlotData}
          onDismiss={this.onDismissLTHA}
          handlePercentageChange={this.handlePercentageChange}
        />
        <CLTEDialog
          hidden={this.state.clteDialogHidden}
          plotData={this.state.clteDialogPlotData}
          onDismiss={this.onDismissCLTE}
          handleShowControlPoints={this.handleShowControlPoints}
        />
        <div style={{ height: '100vh', position: 'absolute' }} ref={this.spanElement}></div>
        <div id="amdc-plot-span" data-tag={this.props.name}>
          {plotComponent}
        </div>
        <div id="amdc-plot-comment" style={{}} ref={this.commentElement}>
          {this.comment}
        </div>
        <div id="amdc-plot-hiddden" style={{ height: '100%', position: 'absolute', display: 'none', pointerEvents: 'none' }}>
          {hiddenComponent}
        </div>
        {this.state.downloadMessage && (
          <div style={{ position: 'absolute', top: '20px', right: '20px', width: 'fit-content', zIndex: '1' }}>
            <MessageBar messageBarType={MessageBarType.info}> {this.state.downloadMessage} </MessageBar>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Plot));
