// eslint-disable-next-line
'use strict';

let websocketclient = null;
let apiMaterials = null;
//console.log('### api ...', apiMaterials);
async function v1caecard(data) {
  let payload = {
    filter: [
      {
        key: 'name',
        value: data.names,
        options: ['clean', 'full'],
      },
    ],
    softwareModelVkey: data.software + '/' + data.softwaremodel,
    unitsystem: data.unitsystem,
    startNumber: 1000,
    temperature: data.temperature,
    temperatureUnitSystem: data.temperatureUnitSystem,
    content: data.appContent,
    dest: 'local',
    responseType: data.responseType || 'application/json',
    moist: data.moist,
    batch: data.batch,
    batchCount: data.batchCount
  };
  let resp = await apiMaterials.downloadMaterialCardV1(payload);
  websocketclient.send(JSON.stringify(resp));
}

async function importJson (data) {
    console.log('### import json:  ', data);
    // websocketclient.send(JSON.stringify({'success': true, action: 'import', 'data': {}}));
    let result = await apiMaterials.uploadMaterialJson(data);
    websocketclient.send(JSON.stringify({success: result.success, action: 'import', data:result}));
}

export function setupWebSocketConnection(props) {
  const { appContent, history, api } = props;
  apiMaterials = api.materials;
  const urlParams = new URLSearchParams(history.location.search);
  let wsUrl = urlParams.get('webChannelbaseUrl');

  // if not websocket connection url available
  if (!wsUrl) {
    return null;
  }

  try {
    websocketclient = new WebSocket(wsUrl);
    if (!websocketclient) {
      throw Error('Could not connect to web server');
    }

    websocketclient.onopen = function (event) {
      console.log('WS connected');
      console.log('WebSocket connection storing in local storage');
      localStorage.setItem('websocketclient', websocketclient);
      console.log(`WebSocket in local storage ${localStorage.getItem('websocketclient')}`);
    };

    websocketclient.onclose = function (event) {
      console.log('WS disconnected');
      localStorage.removeItem('websocketclient');
    };

    websocketclient.onerror = function (event) {
      console.log('WS encountered error.');
      localStorage.removeItem('websocketclient');
    };

    // get all the messages coming from websocket client
    websocketclient.onmessage = async function (event) {
      /**
       * data struture of the data that should be received, so that appropriate handlers could be called
       * eventdata = {
       *  action: import | v1card | ....,
       *  data: '{}' -> json parse data
       * }
       */
      const eventData = JSON.parse(event.data);
      const data = typeof eventData.data === 'object' ? eventData.data : JSON.parse(eventData.data);
      switch (eventData.action) {
        case 'caecard':
          // download cae material data
          data.appContent = appContent;
          v1caecard(data);
          break;
        case 'import':
          // importing the material json
          await importJson(data);
          break;
        default:
          console.log('### no action defined...');
          const res = { success: false, message: 'no action defined...' };
          websocketclient.send(JSON.stringify(res));
          break;
      }
    };
    return websocketclient;
  } catch (error) {
    console.log('### failed to connect to websocket server');
  }
}