/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

import { isEmpty } from 'lodash';
function createFilterGroups({ tags, urlParams, selectedItems }) {
  let groups;

  let groupValues = urlParams.get('tags') ? urlParams.get('tags').split(',') : [];
  groupValues = groupValues.map((value) => {
    return value.split('-')[1];
  });

  function getIsChildSelected(child, selectedItems) {
    if (selectedItems.includes(child.vkey)) {
      return true;
    }

    if (child.children) {
      return child.children.some((grandChild) => getIsChildSelected(grandChild, selectedItems));
    }

    return false;
  }

  function mapChildren(children, parent, group, level, config) {
    if (config && config.startLevel && config.startLevel > level) {
      let results = children.map((child) => {
        return mapChildren(child.children, group, level + 1, config);
      });

      return results.flat();
    }
    if (children) {
      children.sort((a, b) => {
        if (a.pos && b.pos) {
          return a.pos - b.pos;
        } else {
          var nameA = a.name.toUpperCase(); // ignore upper and lowercase
          var nameB = b.name.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        }
      });

      return children
        .map((child) => {
          let isCollapsed = true;

          function setCollapsed(child) {
            if (child.children) {
              child.children.map((grandchild) => {
                if (groupValues.indexOf(grandchild.vkey.toString()) > -1) {
                  isCollapsed = false;
                }

                if (grandchild.children) {
                  setCollapsed(grandchild);
                }

                return grandchild;
              });
            }
          }

          setCollapsed(child);

          return {
            isCollapsed: isCollapsed,
            id: child.vkey.toString(),
            parent: parent,
            name: child.name,
            isTruncated: true,
            value: groupValues.indexOf(child.vkey.toString()) > -1,
            count: child.count,
            children: mapChildren(child.children, child, group, level + 1, config),
            groupVkey: group.useMask ? group.useMask.join('') : '',
          };
        })
        .filter((child) => {
          if (child.count > 0 || (child.id && groupValues.indexOf(child.id) > -1) || !isEmpty(child.children)) {
            return true;
          } else {
            return false;
          }
        });
    }
  }

  groups = tags
    .sort((a, b) => a.pos - b.pos)
    .map((tag) => {
      let filters = mapChildren(tag.children, tag, tag, 0, tag.config);

      const isChildSelected = getIsChildSelected(tag, selectedItems);

      const group = {
        id: tag.name,
        multiple: true,
        name: tag.displayName ? tag.displayName : tag.name,
        type: 'boolean',
        urlParam: 'tags',
        filters: filters,
        isExpanded: tag.config && tag.config.isExpanded !== undefined ? tag.config.isExpanded : true,
        isActive: isChildSelected,
        isTruncated: true,
        isGroup: true,
        config: tag.config,
      };

      return group;
    });
  return groups;
}

export default createFilterGroups;
