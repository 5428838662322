import React, { useEffect, useState } from 'react';

import { DefaultButton, Dialog, DialogType } from '@fluentui/react';

import * as TextMapping from '../../../utils/textMapping';
import { processBuildingBlock } from '../../processBuildingBlock';
import { TextField, PrimaryButton } from '@fluentui/react';

const FeedbackStatus = {
  Failed: 'failed',
  New: 'new',
  Sending: 'sending',
  Sent: 'sent',
};

function getDefaultDialogState({ message = '' }) {
  return {
    message,
    feedbackStatus: FeedbackStatus.New,
    error: null,
  };
}

function BBPageDialog({
  appContent,
  api,
  altairOneBaseUrl = '',
  hidden,
  page,
  bbPageForDialog,
  clearBBPageForDialog,
  getBBPageForDialog,
  onDismiss,
  texts,
}) {
  const [{ message, feedbackStatus, error }, setDialogState] = useState(getDefaultDialogState({ message: '' }));

  const [collapsedVkeys, setCollapsedVkeys] = useState([]);
  const [activeTabMap, setActiveTabMap] = useState(new Map());
  const [layoutMap, setLayoutMap] = useState(new Map());
  const [addedStyles, setAddedStyles] = useState([]);
  const [body, setBody] = useState([]);
  const hasError = message !== null && typeof message !== 'string';
  const disableSubmit = hasError || feedbackStatus === FeedbackStatus.Sending;

  const dialogContentProps = {
    type: DialogType.close,
    closeButtonAriaLabel: 'Close',
  };

  function onTabClick(id, item) {
    setActiveTabMap(new Map(activeTabMap.set(id, item)));
  }

  async function sendFeedback() {
    try {
      setDialogState((prevState) => ({ ...prevState, feedbackStatus: FeedbackStatus.Sending }));
      await api.notification.sendSupportRequest({ appName: 'Material Data Center', comment: message }, altairOneBaseUrl);
      await api.account.sendComment({ appName: 'Material Data Center', comment: message }, altairOneBaseUrl);
      setDialogState({ message: '', feedbackStatus: FeedbackStatus.Sent });
    } catch (e) {
      // TODO:
      setDialogState({
        message,
        error: TextMapping.getUIText(TextMapping.UI_TEXT_FEEDBACK_ERROR_MESSAGE, texts),
        feedbackStatus: FeedbackStatus.Failed,
      });
    }
  }

  useEffect(() => {
    if (!hidden) {
      if (page) {
        let pageParts = page.split('=');
        if (pageParts && pageParts.length > 1) {
          getBBPageForDialog(pageParts[1]);
        }
      }
    } else {
      clearBBPageForDialog();
    }
  }, [page, getBBPageForDialog, clearBBPageForDialog, hidden]);

  function addStyle(id, style) {
    if (!addedStyles.includes(id)) {
      var styleSheet = document.createElement('style');

      let styleText = '';
      if (style && style.length > 0) {
        for (let styleElement of style) {
          styleText += '.bbpage' + styleElement + ' ';
        }
        styleSheet.innerText = styleText;
        document.head.appendChild(styleSheet);
      }

      setAddedStyles((prevStyles) => [...prevStyles, id]);
    }
  }

  function getActiveTab(id) {
    return activeTabMap.get(id)?.props?.itemKey;
  }

  function addFeedback(body) {
    body.push(
      <div className="feedback-component">
        <TextField
          multiline
          rows={4}
          resizable={false}
          onChange={(e, text) => {
            setDialogState((prevState) => ({ ...prevState, error: null, message: text }));
          }}
          value={message}
          placeholder={TextMapping.getUIText(TextMapping.getUIText(TextMapping.UI_TEXT_OPTIONAL_MESSAGE, texts))}
        />
        {feedbackStatus === FeedbackStatus.Failed && <span style={{ color: '#FF0000' }}>{error}</span>}
        {feedbackStatus === FeedbackStatus.Sent && (
          <span style={{ fontWeight: 'bold' }}>
            {TextMapping.getUIText(TextMapping.getUIText(TextMapping.UI_TEXT_FEEDBACK_SENT_MESSAGE, texts))}
          </span>
        )}
        {feedbackStatus !== FeedbackStatus.Sent && (
          <PrimaryButton
            className="submit-feedback-button"
            disabled={disableSubmit}
            text={TextMapping.getUIText(TextMapping.getUIText(TextMapping.UI_TEXT_SUBMIT, texts))}
            styles={{ root: { width: 252, marginTop: 24 } }}
            onClick={sendFeedback}
          />
        )}
        {feedbackStatus === FeedbackStatus.Sent && (
          <DefaultButton
            className="feedback-sent-button"
            text={TextMapping.getUIText(TextMapping.getUIText(TextMapping.UI_TEXT_CLOSE, texts))}
            styles={{ root: { width: 252, marginTop: 24 } }}
            onClick={onDismiss}
          />
        )}
      </div>
    );
  }

  function handleToggler(vkey, value) {
    if (value === true) {
      setCollapsedVkeys((oldCollapsedVkeys) => [...oldCollapsedVkeys, vkey]);
    } else {
      setCollapsedVkeys((oldCollapsedVkeys) =>
        oldCollapsedVkeys.filter((collapsedVkey) => {
          return collapsedVkey !== vkey;
        })
      );
    }
  }

  function onSetLayoutMap(map) {
    setLayoutMap(map);
  }

  useEffect(() => {
    let newBody = [];

    if (bbPageForDialog) {
      for (let buildingBlock of bbPageForDialog) {
        processBuildingBlock({
          appContent,
          buildingBlock,
          body: newBody,
          collapsedVkeys,
          handleToggler,
          onTabClick,
          getActiveTab,
          layoutMap,
          setLayoutMap: onSetLayoutMap,
          addStyle,
          addFeedback,
          texts,
        });
      }
    }

    setBody(newBody);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bbPageForDialog, message, feedbackStatus, error, collapsedVkeys, layoutMap]);

  let className = 'amdc-block bbpage ' + page;

  return (
    <Dialog
      hidden={hidden}
      onDismiss={onDismiss}
      dialogContentProps={dialogContentProps}
      minWidth={300}
      maxWidth={'80%'}
      modalProps={{
        isBlocking: false,
      }}
    >
      <div className={className}>{body}</div>
    </Dialog>
  );
}

export default BBPageDialog;
