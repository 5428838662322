/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { Persona, PersonaSize, DefaultButton, PrimaryButton, Spinner, SpinnerSize } from '@fluentui/react';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { AdditionalServices } from '../AdditionalServices';
import { Avatar, ButtonContainer, Container, Divider, Email, License, FullName, Root, Company } from './styled';
import { isEmpty } from 'lodash';
import * as TextMapping from '../../../utils/textMapping';
import { useAppFrame } from '../../../amdc-app-frame/App';

const NOOP = () => {};

function User({
  altairAdminBaseUrl,
  altairOneBaseUrl,
  email,
  userDisplayRole,
  name,
  company,
  additionalServicesProps,
  disableAdditionalServices,
  onlineHelpUrl,
  injection,
  onAccountSettingsClick = NOOP,
  onLogoutClick = NOOP,
}) {
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const { texts, appContent } = useAppFrame();

  async function handleLogout() {
    if (isLoggingOut) {
      return;
    }

    try {
      const result = onLogoutClick();
      if (result instanceof Promise) {
        setIsLoggingOut(true);
        await result;
      }
    } catch (e) {
      // TODO:Show logout error message?
    } finally {
      setIsLoggingOut(false);
    }
  }

  const personaDetails = {
    // imageUrl: 'avatar',
    imageInitials: name && name.charAt(0),
    text: name,
    secondaryText: email,
  };

  let license = appContent.user && appContent.user.license && !isEmpty(appContent.user.license) ? appContent.user.license[0] : '';
	let username = appContent.user && appContent.user.username ? appContent.user.username : ''
  if (!license) license = username === 'freeuser' ? 'freeversion' : 'nolicense';
  let licensename = TextMapping.getUIText(license + '-drawer', texts, null, '');
  let apptitlelicname = licensename
    ? TextMapping.getUIText(TextMapping.UI_TEXT_LICENSENAMEDRAWER, texts, { licensename: licensename }, '')
    : '';

  return (
    <div>
      <Root data-testid="User-Root">
        <Container data-testid="User-Container">
          <Avatar>
            <Persona {...personaDetails} hidePersonaDetails size={PersonaSize.size32} data-testid="User-Persona" />
          </Avatar>
          <FullName data-testid="User-FullName">
            <span data-testid="User-name" title={name}>{name}</span>
            {!isEmpty(apptitlelicname) && <License data-testid="license">{apptitlelicname}</License>}
            {username !== 'freeuser' && <Email data-testid="User-Email">{email}</Email>}
            {userDisplayRole && <Company data-testid="User-Role">{userDisplayRole}</Company>}
            {company?.name && <Company data-testid="User-company">{company?.name}</Company>}
          </FullName>
        </Container>
      </Root>

      {!disableAdditionalServices && (
        <AdditionalServices
          position="vertical"
          width="90%"
          altairOneBaseUrl={altairOneBaseUrl}
          onlineHelpUrl={onlineHelpUrl}
          {...additionalServicesProps}
        />
      )}
      <br />
      {injection}

      <ButtonContainer>
        {altairAdminBaseUrl && (
          <DefaultButton
            data-testid="User-Button-AccountSettings"
            styles={{ root: { width: 140 } }}
            title="Account Settings"
            onClick={onAccountSettingsClick}
            href={`${altairAdminBaseUrl}/loginauto?redirect_url=/updateprofile`}
            target="_blank"
            rel="nofollow noopener"
            className="ao-accountSettings-button"
          >
            Account Settings
          </DefaultButton>
        )}
        <Divider />
				{username !== 'freeuser' && (
					<PrimaryButton
						data-testid="User-Button-Logout"
						// disabled={isLoggingOut}
						onClick={handleLogout}
						styles={{ root: [{ width: 140 }, isLoggingOut && { cursor: 'progress' }] }}
						className="ao-logout-button"
						title="Logout"
					>
						Logout {isLoggingOut && <Spinner styles={{ root: { marginLeft: 4 } }} size={SpinnerSize.xSmall} />}
					</PrimaryButton>
				)}
        <br />
      </ButtonContainer>
    </div>
  );
}

User.propTypes = {
  altairAdminBaseUrl: PropTypes.string,
  altairOneBaseUrl: PropTypes.string,
  email: PropTypes.string,
  userDisplayRole: PropTypes.string,
  logout: PropTypes.func,
  name: PropTypes.string,
  company: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  }),
  onAccountSettingsClick: PropTypes.func,
  onLogoutClick: PropTypes.func,
  onDismiss: PropTypes.func,
};

User.defaultProps = {
  email: null,
  name: null,
};

export default User;
